import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './LoadsContainer.module.scss';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import get from '../../../services/axios/get';
import LoadItem from './LoadItem/LoadItem';
import del from '../../../services/axios/delete';
import Loader from '../../UI/Loader/Loader';
import { useAppDispatch } from '../../../store/hooks';
import { customAlert } from '../../../store/actions/alert';
import Alert from '../../UI/Alert/Alert';
import patch from '../../../services/axios/patch';
import Modal from '../../../containers/Modal/Modal';
import CreateNewLoad from '../CreateNewLoad/CreateNewLoad';
import { FaCaretDown, FaPlus } from 'react-icons/fa';
import post from '../../../services/axios/post';
import Load, { Charge } from '../../../models/load';
import EditLoad from '../EditLoad/EditLoad';
import put from '../../../services/axios/put';
import { useNavigate } from 'react-router-dom';
import Driver from '../../../models/driver';
import Input from '../../UI/Input/Input';
import Documents from '../Documents/Documents';
import Charges from '../Charges/Charges';
import { CgMenu } from 'react-icons/cg';
import Status from '../../../models/status';
import Account from '../../../models/account';
import PluginDepot from '../../../models/pluginDepot';
import Toggle from '../../UI/Toggle/Toggle';
import { months } from '../../UI/DatePicker/Months/Months';
import Invoicing from '../Invoicing/Invoicing';

interface LoadsContainerProps {
  loadStatus: 'unassigned' | 'assigned' | 'completed' | 'plugged';
}

const LoadsContainer: React.FC<LoadsContainerProps> = (props: LoadsContainerProps) => {
  const dispatch = useAppDispatch();
  const isMounted = useRef(true);
  const [searchTerm, setSearchTerm]: [string, any] = useState('');
  const [deletionId, setDeletionId]: [string | null, any] = useState(null);
  const [completionId, setCompletionId]: [string | null, any] = useState(null);
  const loadsBoxRef = useRef<HTMLDivElement>(null);
  const fullLoadsBoxRef = useRef<HTMLDivElement>(null);
  const [pluggingId, setPluggingId]: [string | null, any] = useState(null);
  const [loads, setLoads]: [Load[], any] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateLoad, setShowCreateLoad] = useState(false);
  const [editingLoad, setEditingLoad]: [Load | null, any] = useState(null);
  const [documentsLoad, setDocumentsLoad]: [Load | null, any] = useState(null);
  const [invoicingLoad, setInvoicingLoad] = useState<Load | null>(null);
  const [additionalChargesLoad, setAdditionalChargesLoad]: [Load | null, any] = useState(null);
  const navigate = useNavigate();
  const [invoicedFilterValue, setInvoicedFilterValue] = useState('all');
  const [monthFilterValue, setMonthFilterValue] = useState(new Date().getMonth())
  const [yearFilterValue, setYearFilterValue] = useState(new Date().getFullYear())
  const [sortBy, setSortBy] = useState<'client' | 'date'>('client')
  const [drivers, setDrivers]: [
    { drivers: Driver[], isLoading: boolean },
    (drivers: any) => any
  ] = useState({ drivers: [], isLoading: true });
  const [statuses, setStatuses]: [
    { statuses: Status[], isLoading: boolean },
    (statuses: any) => any
  ] = useState({ statuses: [], isLoading: true });
  const [accounts, setAccounts]: [
    { accounts: Account[], isLoading: boolean },
    (accounts: any) => any
  ] = useState({ accounts: [], isLoading: true });
  const [pluginDepots, setPluginDepots]: [
    { pluginDepots: PluginDepot[], isLoading: boolean },
    (pluginDepots: any) => any
  ] = useState({ pluginDepots: [], isLoading: true });
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false)
  const subPriceTotalRef = useRef(0);
  const clientPriceTotalRef = useRef(0);


  const submitCharges = (charges: Charge[], loadId: string): void => {
    setAdditionalChargesLoad(null);
    setIsLoading(true);

    patch(
      `/load/${loadId}`,
      { charges: JSON.stringify(charges) },
      () => {
        getLoads();
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
        }
      }
    )
  }

  const getDrivers = useCallback(
    () => {
      get(
        '/driver',
        {},
        (res) => {
          if (isMounted) {
            setDrivers({ drivers: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getPluginDepots = useCallback(
    () => {
      get(
        '/depot',
        { isPlugin: true },
        (res) => {
          if (isMounted) {
            setPluginDepots({ pluginDepots: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getAccounts = useCallback(
    () => {
      get(
        '/account',
        {},
        (res) => {
          if (isMounted) {
            setAccounts({ accounts: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getStatuses = useCallback(
    () => {
      get(
        '/status',
        {},
        (res) => {
          if (isMounted) {
            setStatuses({ statuses: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getLoads = useCallback(
    () => {
      setIsLoading(true);
      setLoads([]);

      const queryParams: {
        loadStatus: string
        from?: string
        to?: string
      } = {
        loadStatus: props.loadStatus
      }

      if (props.loadStatus === 'completed') {
        const fromDate = new Date(`${yearFilterValue}-${monthFilterValue + 1}`)
        const toDate = new Date(fromDate);

        toDate.setMonth(toDate.getMonth() + 1)
        queryParams.from = fromDate.toISOString()
        queryParams.to = toDate.toISOString()
      }

      get(
        `/load`,
        queryParams,
        (res) => {
          if (isMounted) {
            setLoads(res.data);
            setIsLoading(false);
          }
        },
        () => { }
      );
    },
    [props.loadStatus, yearFilterValue, monthFilterValue]
  );

  const createLoad = useCallback(
    (load: Load, onError?: () => void) => {
      post(
        '/load',
        load,
        (res) => {
          if (isMounted) {
            if (load.driver != null) {
              navigate('/loads/assigned')
              getLoads();
            }

            else {
              navigate('/loads/unassigned')
              getLoads();
            }

            setShowCreateLoad(false);
          }
        },
        (err) => {
          if (isMounted) {
            if (onError != null) {
              onError()
            }
            dispatch(customAlert(true, err));
          }
        }
      )
    },
    [dispatch, getLoads, navigate]
  );

  const putLoad = useCallback(
    (load: Load, loadId: string, onError?: () => void) => {
      put(
        `/load/${loadId}`,
        load,
        (res) => {
          if (isMounted) {
            if (load.driver != null && load.driver.alias !== '' && load.loadStatus !== 'completed') {
              navigate('/loads/assigned')
              getLoads();
            }

            else {
              getLoads();
            }

            setEditingLoad(null);
            setInvoicingLoad(null)
          }
        },
        (err) => {
          if (isMounted) {
            if (onError != null) {
              onError()
            }
            dispatch(customAlert(true, err));
          }
        }
      )
    },
    [dispatch, getLoads, navigate]
  );


  const deleteLoad = useCallback(
    (id: string) => {
      del(
        `/load/${id}`,
        {},
        () => {
          if (isMounted) {
            getLoads();
          }
        },
        (err) => {
          dispatch(customAlert(true, 'Oops! Something went wrong'));
        }
      )
    },
    [getLoads, dispatch]
  )

  const setLoadStatus = useCallback(
    (id: string, loadStatus: Load['loadStatus']) => {
      patch(
        `/load/${id}`,
        {
          loadStatus: loadStatus
        }
        ,
        () => {
          if (isMounted) {
            getLoads();
          }
        },
        (err) => {
          dispatch(customAlert(true, 'Oops! Something went wrong'));
        }
      )
    },
    [getLoads, dispatch]
  )

  const findDuplicateClientRefs = (loads: Load[]): string[] => {
    const clientRefCounts = loads.reduce((acc, load) => {
      if (load.clientRef && load.clientRef.trim() !== '') {
        acc[load.clientRef] = (acc[load.clientRef] || 0) + 1;
      }
      return acc;
    }, {} as { [key: string]: number });

    return Object.keys(clientRefCounts).filter((clientRef) => clientRefCounts[clientRef] > 1);
  };

  const sortByDate = (unSortedLoads: Load[]): Load[] => {
    return unSortedLoads.sort((a: Load, b: Load) => {
      const dateA: any = new Date(a.loadDate ?? '1000-01-01');
      const dateB: any = new Date(b.loadDate ?? '1000-01-01');

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;

      if (a.client < b.client) return -1;
      if (a.client > b.client) return 1;

      return 0;
    });
  }

  const sortByClient = (unSortedLoads: Load[]): Load[] => {
    return unSortedLoads.sort((a: Load, b: Load) => {
      if (props.loadStatus === 'assigned' || props.loadStatus === 'unassigned') {
        if (a.client < b.client) return -1;
        if (a.client > b.client) return 1;
      }

      const dateA: any = new Date(a.loadDate ?? '1000-01-01');
      const dateB: any = new Date(b.loadDate ?? '1000-01-01');

      if (props.loadStatus === 'completed') {
        return dateB - dateA;
      }

      return dateA - dateB;
    });
  }

  const sortLoads = (unSortedLoads: Load[]): Load[] => {
    let sortedLoads: Load[]

    switch (sortBy) {
      case 'client':
        sortedLoads = sortByClient(unSortedLoads)
        break;
      case 'date':
        sortedLoads = sortByDate(unSortedLoads)
        break;
      default:
        sortedLoads = sortedLoads = sortByClient(unSortedLoads)
        break;
    }
    return sortedLoads
  }

  const renderLoads = () => {
    let subPriceTotal = 0
    let clientPriceTotal = 0

    let filteredLoads = loads.filter(load => {
      return Object.values(load).some(value => {
        if (typeof value === 'object' && value != null) {
          return Object.values(value).some(innerValue => {
            console.log(innerValue)
            return String(
              innerValue
            ).toLowerCase().includes(searchTerm.toLowerCase())
          })
        }

        return String(value).toLowerCase().includes(searchTerm.toLowerCase())
      }
      );
    });

    if (invoicedFilterValue === 'YES') {
      filteredLoads = filteredLoads.filter(load => {
        return load.invoiceNumber != null && load.invoiceNumber !== '';
      });
    } else if (invoicedFilterValue === 'NO') {
      filteredLoads = filteredLoads.filter(load => {
        return load.invoiceNumber == null || load.invoiceNumber === '';
      });
    }

    const sortedLoads = sortLoads(filteredLoads)
    const duplicateClientRefs = findDuplicateClientRefs(sortedLoads);

    const sortedLoadComponents = sortedLoads.map((load, index) => {
      const subPrice = parseFloat(load.subPrice?.split(' ')[1] ?? '0')
      const clientPrice = parseFloat(load.clientPrice?.split(' ')[1] ?? '0')

      subPriceTotal = subPriceTotal + (!Number.isNaN(subPrice) ? subPrice : 0)
      clientPriceTotal = clientPriceTotal + (!Number.isNaN(clientPrice) ? clientPrice : 0)

      const shadeClass = index % 2 === 0 ? 'even' : 'odd';
      const isDuplicate = load.clientRef != null ? duplicateClientRefs.includes(load.clientRef) : false;
      return <LoadItem
        pluginDepots={pluginDepots}
        accounts={accounts}
        statuses={statuses}
        refresh={getLoads}
        loadStatus={props.loadStatus}
        key={load.id}
        showDocuments={setDocumentsLoad}
        showInvoicing={setInvoicingLoad}
        showAdditionalCharges={setAdditionalChargesLoad}
        shade={shadeClass}
        load={load}
        deleteLoad={(id) => setDeletionId(id)}
        completeLoad={(id) => setCompletionId(id)}
        plugLoad={(id) => setPluggingId(id)}
        unCompleteLoad={(id, loadStatus) => setLoadStatus(id, loadStatus)}
        edit={setEditingLoad}
        putLoad={putLoad}
        createLoad={createLoad}
        drivers={drivers}
        isFull={isFullWidth}
        isDuplicate={isDuplicate}
      />
    });

    subPriceTotalRef.current = subPriceTotal
    clientPriceTotalRef.current = clientPriceTotal

    return sortedLoadComponents
  }

  const toggle = () => {
    setIsFullWidth(prevState => !prevState)
  }

  useEffect(() => {
    getLoads()
  }, [
    monthFilterValue,
    yearFilterValue
  ])

  useEffect(() => {
    getLoads();
    getDrivers();
    setSearchTerm('');
    setInvoicedFilterValue('ALL');
    getStatuses();
    getAccounts();
    getPluginDepots();

    return () => {
      isMounted.current = false;
    }
  }, [
    getLoads,
    getDrivers,
    props.loadStatus,
    setSearchTerm,
    getStatuses,
    getAccounts,
    getPluginDepots
  ])

  useEffect(() => {
    const now = new Date()

    setMonthFilterValue(now.getMonth())
    setYearFilterValue(now.getFullYear())

    if (loadsBoxRef.current) {
      loadsBoxRef.current.scrollTop = 0;
      loadsBoxRef.current.scrollLeft = 0;
    }
    if (fullLoadsBoxRef.current) {
      fullLoadsBoxRef.current.scrollTop = 0;
      fullLoadsBoxRef.current.scrollLeft = 0;
    }
  }, [props.loadStatus]);

  return (
    <>
      {additionalChargesLoad != null ? (
        <Modal close={() => setAdditionalChargesLoad(null)}>
          <Charges submitCharges={submitCharges} loadId={(additionalChargesLoad as Load).id} charges={(additionalChargesLoad as Load).charges ?? '[]'} />
        </Modal>
      ) : null}

      {documentsLoad != null ? (
        <Modal close={() => setDocumentsLoad(null)}>
          <Documents load={documentsLoad} />
        </Modal>
      ) : null}

      {invoicingLoad != null ? (
        <Modal close={() => setInvoicingLoad(null)}>
          <Invoicing load={invoicingLoad} putLoad={putLoad} close={() => setInvoicingLoad(null)} />
        </Modal>
      ) : null}

      <Container>
        <Alert
          confirm={(confirmation) => {
            if (confirmation && deletionId != null) {
              deleteLoad(deletionId);
              setDeletionId(null);
            } else {
              setDeletionId(null);
            }
          }}
          message={
            <div>
              Are you sure you want to delete this load?
            </div>
          }
          isOpen={deletionId !== null}
          isConfirm
        />

        <Alert
          confirm={(confirmation) => {
            if (confirmation && completionId != null) {
              setLoadStatus(completionId, 'completed');
              setCompletionId(null);
            } else {
              setCompletionId(null);
            }
          }}
          message={
            <div>
              Are you sure you want to complete this load?
            </div>
          }
          isOpen={completionId !== null}
          isConfirm
        />

        <Alert
          confirm={(confirmation) => {
            if (confirmation && pluggingId != null) {
              setLoadStatus(pluggingId, 'plugged');
              setPluggingId(null);
            } else {
              setPluggingId(null);
            }
          }}
          message={
            <div>
              Are you sure you want to plug this load?
            </div>
          }
          isOpen={pluggingId !== null}
          isConfirm
        />

        {showCreateLoad ? (
          <Modal close={() => setShowCreateLoad(false)}>
            <CreateNewLoad close={() => setShowCreateLoad(false)} createLoad={createLoad} />
          </Modal>
        ) : null}

        {editingLoad != null ? (
          <Modal close={() => setEditingLoad(null)}>
            <EditLoad close={() => setEditingLoad(null)} putLoad={putLoad} load={editingLoad} />
          </Modal>
        ) : null}

        <Row className='g-0'>
          <Col xs='12'>
            {props.loadStatus === 'unassigned' ? (
              <div className={classes['create-new-load']}>
                <div onClick={() => setShowCreateLoad(true)}>
                  <FaPlus /> Create New Load
                </div>

              </div>
            ) : null}
          </Col>
        </Row>

        <Row className='g-0'>
          <Col>
            <div className={[classes.search, 'mt-3 mx-3'].join(' ')}>
              <Input
                elementType={'text'}
                elementConfig={{
                  type: 'text',
                  placeholder: 'Search...',
                }}
                value={searchTerm}
                change={(e) => setSearchTerm(e.target.value)}
                id='search'
                labelHidden
                inputStyle='main'
              />
            </div>
          </Col>

          {props.loadStatus === 'completed' ? (
            <>
              <Col className='mt-4'>
                <UncontrolledDropdown direction={'down'} className={[classes['filter-dropdown']].join(' ')}>
                  <DropdownToggle caret className={[classes['filter-dropdown-toggle']].join(' ')}>Invoiced:  <span className={classes['filter-value']}>{invoicedFilterValue}</span></DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setInvoicedFilterValue('ALL')}>ALL</DropdownItem>
                    <DropdownItem onClick={() => setInvoicedFilterValue('YES')}>YES</DropdownItem>
                    <DropdownItem onClick={() => setInvoicedFilterValue('NO')}>NO</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>

              <Col className='mt-4'>
                <UncontrolledDropdown direction={'down'} className={[classes['filter-dropdown']].join(' ')}>
                  <DropdownToggle caret className={[classes['filter-dropdown-toggle']].join(' ')}>Year:  <span className={classes['filter-value']}>{yearFilterValue}</span></DropdownToggle>
                  <DropdownMenu>
                    {/* TODO: This is trash code, fix it */}
                    {[0, 1, 2, 3, 4].map(value => {
                      const now = new Date()
                      return <DropdownItem onClick={() => setYearFilterValue(now.getFullYear() - value)}>{now.getFullYear() - value}</DropdownItem>
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>

              <Col className='mt-4'>
                <UncontrolledDropdown direction={'down'} className={[classes['filter-dropdown']].join(' ')}>
                  <DropdownToggle caret className={[classes['filter-dropdown-toggle']].join(' ')}>Month:  <span className={classes['filter-value']}>{months[monthFilterValue]}</span></DropdownToggle>
                  <DropdownMenu>
                    {/* TODO: This is trash code, fix it */}
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(value => {
                      return <DropdownItem onClick={() => setMonthFilterValue(value)}>{months[value]}</DropdownItem>
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </>
          ) : <Col></Col>}

          <Col className={classes['toggle']}>
            <div>
              <div>Full View</div>
              <Toggle toggle={toggle} isToggled={isFullWidth} />
            </div>
          </Col>
        </Row>

      </Container>
      <Container fluid>
        <Row className='g-0'>
          <Col>
            {isFullWidth ? (
              <div className={[classes['full-loads-box'], props.loadStatus === 'completed' ? classes['completed-loads-box'] : ''].join(' ')} ref={fullLoadsBoxRef}>
                <div className={classes['full-headers']}>
                  <div className={classes['sort-header']} onClick={() => setSortBy('client')}>Client <FaCaretDown color='var(--theme-green)' /></div>
                  <div>Driver</div>
                  <div className={classes['sort-header']} onClick={() => setSortBy('date')}>Date <FaCaretDown color='var(--theme-green)' /></div>
                  <div>Load Status</div>
                  <div>Empty Depot</div>
                  <div>Load Point 1</div>
                  <div>Load Point 2</div>
                  <div>Booking Time</div>
                  <div>Booking Number</div>
                  <div>Booking Ref</div>
                  <div>Client Ref</div>
                  <div>Exporter Ref</div>
                  <div>Genset</div>
                  <div>Weigh Method</div>
                  <div>Container No.</div>
                  <div>Seal Number</div>
                  <div>Temptale Type</div>
                  <div>Temptale Number</div>
                  <div>Temp Code</div>
                  <div>Temp Setting</div>
                  <div>Vent Type</div>
                  <div>Commodity</div>
                  <div>Shipping Line</div>
                  <div>Vessel</div>
                  <div>Voyage</div>
                  <div>Port of Discharge</div>
                  {props.loadStatus === 'completed' ?
                    <>
                      <div>Client Price</div>
                      <div>Sub Price</div>
                      <div>Invoice No.</div>
                    </>
                    : null
                  }

                  <div>Pre Advised</div>
                  <div>Booked</div>
                </div>

                <div className={classes['full-loads']}>
                  {renderLoads()}
                </div>
              </div>
            ) : (
              <div className={[classes['loads-box'], props.loadStatus === 'completed' ? classes['completed-loads-box'] : ''].join(' ')} ref={loadsBoxRef}>
                <Row className={[classes.columns, 'g-0'].join(' ')}>
                  <Col className={classes['sort-header']} onClick={() => setSortBy('client')}>Client <FaCaretDown color='var(--theme-green)' /></Col>
                  <Col className={[classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>Driver</Col>

                  {props.loadStatus === 'plugged' ? (
                    <>
                      <Col className={[classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>Date Plugged</Col>
                      <Col className={[classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>Date XHauled</Col>
                      <Col className={[classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>Container No.</Col>
                      <Col className={[classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>Vessel</Col>
                    </>
                  ) : (
                    <>
                      <Col className={classes['sort-header']} onClick={() => setSortBy('date')}>Date <FaCaretDown color='var(--theme-green)' /></Col>
                      <Col className={[classes['hide-mobile'], classes['equal-width-columns']].join(' ')}>Booking Number</Col>
                    </>
                  )}

                  {props.loadStatus !== 'plugged' ? (
                    <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Booking Ref</Col>
                  ) : null}

                  <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Client Ref</Col>
                  {props.loadStatus !== 'completed' && props.loadStatus !== 'plugged' ? (
                    <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Vessel</Col>
                  ) : null}
                  {props.loadStatus === 'unassigned' ? (
                    <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Empty Depot</Col>
                  ) : null}

                  {props.loadStatus === 'plugged' ? (
                    <>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Plugin Depot</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Booking Number</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Booking Time</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Pre Advised</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Account</Col>
                    </>
                  ) : (
                    <>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Load Point 1</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Load Point 2</Col>
                    </>
                  )}

                  {props.loadStatus !== 'plugged' ? (
                    <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                      <div className={classes.checkboxes}>
                        <div>Genset</div>

                        {props.loadStatus === 'completed' ? (
                          <div>Charges</div>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}

                  {props.loadStatus === 'completed' ? (
                    <>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Client Price</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Sub Price</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Container No.</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Invoice No.</Col>
                    </>
                  ) : null}

                  {props.loadStatus === 'assigned' ? (
                    <>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Load Status</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>Booking Time</Col>
                      <Col className={[classes['hide-mobile'], classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
                        <div className={classes.checkboxes}>
                          <div>Pre Advised</div>

                          <div>Booked</div>
                        </div>
                      </Col>
                    </>
                  ) : null}

                  <Col className={classes['fit-content-column']}>
                    <div style={{ marginRight: '1rem', paddingRight: '16px', paddingLeft: '12px', opacity: 0 }}>
                      <CgMenu size={16} />
                    </div>
                  </Col>
                </Row>

                <div className={classes['loads-content']}>
                  {isLoading ? <div className={classes['loader-container']}>
                    <Loader size='large' />
                  </div> : renderLoads()}
                </div>
              </div>
            )}
          </Col>
        </Row>

        {props.loadStatus === 'completed' ? <Row >
          <Col></Col>

          <Col>
            <div>Total Client Price: R{clientPriceTotalRef.current}</div>
          </Col>

          <Col>
            <div>Total Sub Price: R{subPriceTotalRef.current}</div>
          </Col>
        </Row> : null}
      </Container>
    </>
  );
};

export default LoadsContainer;
